<template>
    <div>
        <div class="content">
            <el-form :model="dataForm" ref="cmdRealDataForm" label-width="150px" :inline="true">
                <el-form-item >
                    <template slot="label">
                        <div>电池均衡是否激活</div>
                    </template>
                    <el-switch v-model="dataForm.equalizationActiveStatus" :active-value="'1'" :inactive-value="'0'"/>
                </el-form-item>
                <div class="tip">注意：电池类型为Flooded、Lib和User-define，且电池均衡开启时，才能设置该指令。若电池均衡关闭，该指令为非激活状态</div>
            </el-form>
        </div>
        <div class="btn-box">
            <el-button @click="submit" type="primary">设置</el-button>
        </div>
    </div>
</template>

<script>
import loading from "@/utils/cmdLoading";
import global from "@/components/Global";
import message from "@/utils/message";
import mqttService from "@/api/mqtt-service";

export default {

    name: 'battery-equalization-active-inactive',
    data() {

        return {

            command: {

                commandCodeR: 'get_battery_equalization_status_parameters',
                commandCodeW: 'set_battery_equalization_now'
            },
            deviceSn: '',
            dataForm: {

                equalizationActiveStatus: '0'
            }
        }
    },
    methods: {

        /**
         * 页面初始化.
         */
        initPage(deviceSn) {

            this.deviceSn = deviceSn;
            this.cmdLoading = loading.loading('读取中...', 30);
            let that = this;
            let option = {

                loading: that.cmdLoading,
                data: {

                    deviceSn: that.deviceSn,
                    commandCode: that.command.commandCodeR
                },
                mqttMessageCallback: res => {

                    if (res.code === global.response_status_success_obj) {

                        that.initSuccessCallBack(res.data);
                    } else {

                        message.error('指令下发失败');
                    }
                }
            };
            setTimeout(() => {

                mqttService.disconnectMqtt();
            }, 30000);
            mqttService.sendEsaioBusinessCommand(option);
        },
        /**
         * 设置提交.
         */
        submit() {

            this.cmdLoading = loading.loading('指令下发中...', 30);
            let that = this;
            let option = {

                loading: that.cmdLoading,
                data: {

                    deviceSn: that.deviceSn,
                    commandCode: that.command.commandCodeW,
                    data: that.dataForm
                },
                mqttMessageCallback: res => {

                    that.submitSuccessCallback(res);
                }
            };
            setTimeout(() => {

                mqttService.disconnectMqtt();
            }, 30000);
            mqttService.sendEsaioBusinessCommand(option);
        },

        /**
         * 查询指令回调.
         */
        initSuccessCallBack(data) {

            this.dataForm.equalizationActiveStatus = data.equalizationActiveStatus;
        },
        /**
         * 设置指令回调.
         * @param data 指令响应数据
         */
        submitSuccessCallback(data) {

            if (data.code === global.response_status_success_obj) {

                message.success('电池均衡是否激活设置成功');
            } else {

                message.error('指令下发失败');
            }
        }
    }
}
</script>

<style lang="scss" scoped>

.content {

    height: 490px;
    margin: 10px 0 1% 5%;
    .tip {

        margin-left: 28px;
        color: gray;
        font-size: 11px;
    }
}

.btn-box {

    text-align: center;
}
</style>